<template>
  <div>
    <Loader />
<!--    <Customizer @onLogo="changeLogo" />-->
    <div class="wrapper">
      <!-- TOP Nav Bar -->
      <mainNav></mainNav>
      <div class="cover_header_page" v-if="$route.name !== 'index'"  :style="{'background': 'url(' + cover + ')'}">
        <div class="overlay-cover-page d-flex align-items-end">
          <b-container>
            <b-row>
              <BreadCrumb />
            </b-row>
          </b-container>
        </div>
      </div>
      <div id="content-page" class="content-page" :class="horizontal ? 'ml-0' : ''">
      <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
                    :leave-active-class="`animated ${animated.exit}`">
          <router-view/>
        </transition>
      </div>
    </div>
    <LayoutFooter />
    <indecatorMenu v-if="!indicatorFlag"></indecatorMenu>
  </div>
</template>
<script>
import Loader from '../components/core/loader/Loader'
import indecatorMenu from '@/components/curva/indecatorMenu'
// import DefaultNavBar from '../components/core/navbars/DefaultNavBar'
import HorizontalItems from '../FackApi/json/HorizontalMenu'
import profile from '../assets/images/user/user.jpg'
// eslint-disable-next-line import/no-duplicates
import loader from '../assets/images/logo.png'
import { core } from '@/config/pluginInit'
import { mapActions, mapGetters } from 'vuex'
/* import Lottie from '../components/core/lottie/Lottie' */
/* import Customizer from './Components/Customizer' */
// eslint-disable-next-line import/no-duplicates
import WhiteLogo from '../assets/images/logo.png'
import BreadCrumb from '../components/core/breadcrumbs/BreadCrumb'
import LayoutFooter from './Components/LayoutFooter'
// eslint-disable-next-line import/no-duplicates
import darkLoader from '../assets/images/logo.png'
import mainNav from '@/components/curva/mainNav'
import EventBus from '@/eventBus'
export default {
  name: 'HorizantalLayout',
  components: {
    LayoutFooter,
    /*     Customizer, */
    Loader,
    mainNav,
    indecatorMenu,
    /*     DefaultNavBar */
    BreadCrumb
  },
  mounted () {
    this.updateRadio()
  },
  computed: {
    ...mapGetters({
      cartCount: 'Ecommerce/cartCountState',
      cartItems: 'Ecommerce/cartState',
      selectedLang: 'Setting/langState',
      langsOptions: 'Setting/langOptionState',
      darkMode: 'Setting/darkModeState',
      colors: 'Setting/colorState'
    }),
    toggleSideIcon () {
      let show = true
      switch (this.$route.name) {
        case 'dashboard.home-1':
        case 'dashboard.home-3':
        case 'dashboard.home-4':
        case 'dashboard.home-5':
        case 'dashboard.home-6':
          show = false
          break
      }
      return show
    }
  },
  // sidebarTicket
  data () {
    return {
      mode: true,
      cover: require('@/assets/images/curva/cover_pages.png'),
      userName: '',
      userRole: '',
      horizontal: true,
      mini: false,
      animated: { enter: 'fadeInUp', exit: 'fadeOut' },
      items: HorizontalItems,
      userProfile: profile,
      onlyLogo: true,
      onlyLogoText: true,
      sidebarGroupTitle: false,
      logo: loader,
      usersList: [],
      rtl: false,
      indicatorFlag: false,
      SmallSidebarLogo: WhiteLogo,
      message: [
        { image: require('../assets/images/user/user-01.jpg'), name: 'Nik Emma Watson', date: '13 jan' },
        { image: require('../assets/images/user/user-02.jpg'), name: 'Greta Life', date: '14 Jun' },
        { image: require('../assets/images/user/user-03.jpg'), name: 'Barb Ackue', date: '16 Aug' },
        { image: require('../assets/images/user/user-04.jpg'), name: 'Anna Sthesia', date: '21 Sept' },
        { image: require('../assets/images/user/user-05.jpg'), name: 'Bob Frapples', date: '29 Sept' }
      ],
      notification: [
        { image: require('../assets/images/user/user-01.jpg'), name: 'Nik Emma Watson', date: '23 hour ago', description: 'Enjoy smart access to videos, games' },
        { image: require('../assets/images/user/user-02.jpg'), name: 'Greta Life', date: '14 hour ago', description: 'Google Chromecast: Enjoy a world of entertainment' },
        { image: require('../assets/images/user/user-03.jpg'), name: 'Barb Ackue', date: '16 hour ago', description: 'Dell Inspiron Laptop: Get speed and performance from' },
        { image: require('../assets/images/user/user-04.jpg'), name: 'Anna Sthesia', date: '21 hour ago', description: 'Deliver your favorite playlist anywhere in your home ' },
        { image: require('../assets/images/user/user-05.jpg'), name: 'Bob Frapples', date: '11 hour ago', description: 'MacBook Air features up to 8GB of memory, a fifth-generation' }
      ],
      notBookmarkRouts: [
        'dashboard.home-1',
        'dashboard.home-2',
        'dashboard.home-3',
        'dashboard.home-4',
        'dashboard.home-5',
        'dashboard.home-6'
      ]
    }
  },
  methods: {
    layoutSetting (routeName) {
      switch (routeName) {
        case 'dashboard.home-3':
          break
        case 'dashboard.home-4':
          break
        default:
          if (this.darkMode) {
            this.logo = darkLoader
          } else {
            this.logo = loader
          }
          break
      }
    },
    changeLogo (e) {
      this.logo = e
    },
    updateRadio () {
      this.mini = this.$store.getters['Setting/miniSidebarState']
    },
    sidebarMini () {
      core.triggerSet()
      this.$store.dispatch('Setting/miniSidebarAction')
      this.updateRadio()
    },
    ...mapActions({
      modeChange: 'Setting/layoutModeAction',
      changeDir: 'Setting/changeDir',
      removeToCart: 'Ecommerce/removeToCartAction',
      rtlAdd: 'Setting/setRtlAction',
      rtlRemove: 'Setting/removeRtlAction'
    })
  },
  created () {
    EventBus.$on('hide-indicator', (flag) => {
      this.indicatorFlag = flag
    })
    // localStorage.setItem('dark', true)
    // this.mode = localStorage.getItem('dark')
  },
  watch: {
    $route: function (to, from) {
      localStorage.setItem('lastPage', from.name)
    }
  }
}
</script>
<style>
@import url("../assets/css/custom.css");
@import url("../assets/css/PriceSlider.css");
.overlay-cover-page{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  /*
  background: linear-gradient(90deg, rgba(0,0,0,0.49933476808692223) 0%, rgba(255,255,255,0.5130427170868348) 42%, rgba(0,0,0,0.49653364763874297) 100%);
  */
}
</style>
