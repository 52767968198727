<template>
  <b-col cols="12">
    <iq-card class="bg-transparent">
      <b-row class="d-flex align-items-center mb-2">
        <b-col lg="12">
          <div class="d-flex align-items-center bg-transparent">
            <b-breadcrumb class="bg-transparent mb-0 mt-1">
              <b-breadcrumb-item to="/" class="text-initial">
                <i class="ri-home-4-line mr-1"></i> {{$t('mainNav.home')}}
              </b-breadcrumb-item>
              <b-breadcrumb-item
                  class="text-white"
                  v-for="item in $route.meta.breadCrumb"
                  :key="item.text"
                  :active="item.active"
                  :to="{name: item.to, params:{...item.params}}"
              >
                {{ $t(item.text) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
<!--            <b-breadcrumb :items="page.breadCrumb" class="bg-transparent mb-0 mt-1" />-->
          </div>
        </b-col>
      </b-row>
    </iq-card>
  </b-col>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'BreadCrumb',
  watch: {
    $route (to, from) {
      this.checkRoute()
    }
  },
  mounted () {
    this.currentRoute = this.navList.find(item => item.link.name === this.$route.name)
    const book = this.bookmark.find(item => item.link.name === this.$route.name)
    if (book !== undefined) {
      this.selectedBookmark = true
    }
  },
  computed: {
    ...mapGetters({
      page: 'Setting/activePage',
      navList: 'Setting/globalSearchState',
      bookmark: 'Setting/bookmarkState'
    })
  },
  data () {
    return {
      bookmarkSearch: '',
      currentRoute: {},
      selectedBookmark: false
    }
  },
  methods: {
    ...mapActions({
      addToBookmarkState: 'Setting/addBookmarkAction',
      removeToBookmarkState: 'Setting/removeBookmarkAction'
    }),
    removeToBookmark (item) {
      this.removeToBookmarkState(item)
      this.selectedBookmark = false
    },
    addToBookmark (item) {
      this.addToBookmarkState(item)
      this.selectedBookmark = true
    },
    checkRoute () {
      this.currentRoute = this.navList.find(item => item.link.name === this.$route.name)
      const book = this.bookmark.find(item => item.link.name === this.$route.name)
      if (book !== undefined) {
        this.selectedBookmark = true
      } else {
        this.selectedBookmark = false
      }
    }
  }
}
</script>
